<template>
  <div
    v-if="tabs?.length > 0"
    class="filter-secondary__wrapper"
    :class="{ 'is-hidden': isHidden }"
  >
    <div
      ref="scrollerRef"
      class="filter-secondary"
      @scroll="setScrollPosition"
    >
      <transition name="fade-in">
        <div
          v-if="!clientDeviceStore.isMobile && !isScrolledOnStart"
          class="filter-secondary__scroll-control is-left"
        >
          <AppIcon
            icon="arrow-left"
            :is-raw="true"
            @click="triggerScroll(false)"
            @keypress="triggerScroll(false)"
          />
        </div>
      </transition>
      <div class="filter-secondary__inner">
        <div
          class="filter-secondary__item"
          :class="{ 'is-active': isAllActive }"
          @click="handleReset(false)"
          @keypress="handleReset(false)"
        >
          {{ useTranslation('general', 'all') }}
        </div>
        <div
          v-for="item in tabs"
          :key="`${item.collection}-${item.id}`"
          class="filter-secondary__item"
          :class="{ 'is-active': getTabIndex(item) !== -1 }"
          @click="handleSelection(item, false)"
          @keypress="handleSelection(item, false)"
        >
          {{ item.name }}
        </div>
      </div>
      <transition name="fade-in">
        <div
          v-if="!clientDeviceStore.isMobile && !isScrolledOnEnd"
          class="filter-secondary__scroll-control is-right"
        >
          <AppIcon
            icon="arrow-right"
            :is-raw="true"
            @click="triggerScroll"
            @keypress="triggerScroll"
          />
        </div>
      </transition>
      <transition name="fade-in">
        <div
          v-if="clientDeviceStore.isMobile && !scrolledToEnd"
          class="filter-secondary__fade"
        />
      </transition>
    </div>
  </div>
</template>

<script setup>
const clientDeviceStore = useClientDeviceStore();
const activeTabs = reactive([]);

defineProps({
    data: {
        type: Object,
        default: () => {},
    },
    isHidden: {
        type: Boolean,
        default: false,
    },
    tabs: {
        type: Array,
        default: () => [],
    },
    preselectedItems: {
        type: Array,
        default: () => [],
    },
});

/*
  Get items
*/

/*
    Handle selection
*/
const emit = defineEmits(['set-filter-relationals']);
const isAllActive = computed(() => activeTabs.length === 0);

const getTabIndex = (data) => {
    if (!data) return -1;

    return activeTabs.findIndex(
        (item) => item.id === data.id && item.collection === data.collection,
    );
};

const handleEmit = (isInitial) => {
    const mapped = {};
    activeTabs.forEach((item) => {
        if (!mapped[item.collection]) {
            mapped[item.collection] = [];
        }

        mapped[item.collection].push(item.name);
    });

    emit('set-filter-relationals', {
        values: mapped,
        isInitial,
    });
};

const handleSelection = (data, isInitial) => {
    isAllActive.value = false;

    /* Check if the clicked item already exists */
    const spliceIndex = getTabIndex(data);

    if (spliceIndex !== -1) {
        /* Clicked item already exists, so we have to remove it here */
        activeTabs.splice(spliceIndex, 1);
    } else {
        /* Clicked item does not exists, push it into active filters */
        activeTabs.push(data);
    }

    handleEmit(isInitial);
};

const handleReset = () => {
    const { length } = activeTabs;
    activeTabs.splice(0, length);
    handleEmit(false);
};

/*
      Handle scroll button
*/
const scrollerRef = ref(null);
const isScrolledOnEnd = ref(true);
const isScrolledOnStart = ref(true);

const scrolledToEnd = ref(false);
const handleScroll = () => {
    const { scrollLeft, scrollWidth, clientWidth } = scrollerRef.value;
    scrolledToEnd.value = scrollLeft + clientWidth >= scrollWidth - 60;
};

const setScrollPosition = () => {
    if (scrollerRef.value) {
        const { scrollWidth, clientWidth, scrollLeft } = scrollerRef.value;

        isScrolledOnStart.value = scrollLeft === 0;
        isScrolledOnEnd.value = Math.abs(scrollWidth - clientWidth - scrollLeft) < 1;

        handleScroll();
    }
};

onMounted(() => {
    setScrollPosition();
});

const SCROLL_STEP = 200;
const { gsapScrollTo } = useGsap();
const triggerScroll = (right = true) => {
    const { scrollLeft } = scrollerRef.value;
    const scrollTarget = right ? scrollLeft + SCROLL_STEP : scrollLeft - SCROLL_STEP;

    gsapScrollTo(scrollerRef, {
        duration: 0.4,
        ease: 'Circ.easeInOut',
        scrollTo: { x: scrollTarget },
    });
};

</script>

<style lang="scss" scoped>
$transitionDuration: 0.2s;
$scrollControlWidth: 46px;

.filter-secondary__wrapper {
    @include grid-columns(1, var(--grid-columns));
    position: relative;
    overflow: hidden;
    border-radius: 12px;
    margin-bottom: 26px;

    @include mobile {
        @include grid-full;

        position: relative;
        overflow: hidden;
        border-radius: 0;
    }

    &.is-hidden {
        display: none;
    }

    .fade-in-enter-active,
    .fade-in-leave-active {
        transition: opacity 0.15s ease-in-out;
    }
}

.filter-secondary__inner {
    @include fluid('padding', 14px, 30px);

    display: flex;
    flex-wrap: nowrap;
    border-radius: 12px;
    background: $C_GREEN_BRIGHT;
    column-gap: 10px;
    white-space: nowrap;

    @include mobile {
        padding-right: 14px;
        margin-right: var(--page-padding);
        margin-left: var(--page-padding);
    }

    .is-recommendation & {
        background: $C_WHITE;
    }
}

.filter-secondary__item {
    @include typo-size('nav');
    @include typo-font('regular');
    @include fluid('padding-top', 7px, 10px);
    @include fluid('padding-bottom', 7px, 10px);
    @include fluid('padding-left', 10px, 20px);
    @include fluid('padding-right', 10px, 20px);

    border: 2px solid $C_SECONDARY;
    border-radius: 50px;
    backface-visibility: hidden;
    color: $C_SECONDARY;
    cursor: pointer;
    transition: background-color $transitionDuration ease-in-out, color $transitionDuration ease-in-out, transform $transitionDuration ease-in-out;
    white-space: nowrap;

    &:hover {
        transform: scale(1.02);
    }
    &.is-active{
        background: $C_SECONDARY;
        color: $C_WHITE;
    }
}

.filter-secondary {
    @include remove-scrollbars;
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    border-radius: 12px;
    overflow-x: auto;
    white-space: nowrap;

    @include mobile {
        border-radius: 0;
    }
}

.filter-secondary__scroll-control {
    position: absolute;
    z-index: 100;
    top: 0;
    display: flex;
    width: $scrollControlWidth;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: $C_GREEN_OLIVE_LIGHT;
    cursor: pointer;

    &.is-right {
        right: 0;
    }

    &.is-left {
        left: 0;
    }

    .app-icon {
        path {
            fill: $C_WHITE;
        }
    }
}

.filter-secondary__fade {
    position: absolute;
    top: 0;
    right: 0;
    width: calc(var(--page-padding) * 2);
    height: 100%;
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
    border-bottom-right-radius: 12px;
    border-top-right-radius: 12px;
    pointer-events: none;
}
</style>
